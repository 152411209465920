import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Homepage from './Components/Homepage';
import Test from './Components/Test';

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={'/'}>
        <Routes>
          <Route index path='/' element={<Homepage />} />
          <Route index path='/test' element={<Test />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
